import axios from 'axios';
import { executeCaptcha } from './captcha';

const ACTIVE_POST_MESSAGE_CLASS = 'registration__result--show';

/** @type {HTMLFormElement} */
export const formElement = document.querySelector('[data-form]');

/** @type {HTMLElement} */
const successMessageElement = document.querySelector('[data-form-success]');

/** @type {HTMLElement} */
const errorMessageElement = document.querySelector('[data-form-error]');

/**
 * @param {HTMLInputElement} input
 * @returns {String}
 */
const getValidationErrorType = (input) => {
    const { validity } = input;
    const isRadio = input.type === 'radio';
    const isCheckbox = input.type === 'checkbox';

    const emptyErrorType = isCheckbox || isRadio ? input.type : 'empty';

    if (validity.valueMissing) return emptyErrorType;
    if (validity.patternMismatch) return 'pattern';
    if (validity.typeMismatch) return 'format';

    return '';
};

/** @param {HTMLLabelElement} label */
const configureBoundElement = (label) => {
    const boundElementName = label.getAttribute('data-bind');
    const boundElements = document.querySelectorAll(`[data-${boundElementName}]`);

    if (boundElements.length && boundElementName === 'poll') {
        [...boundElements].forEach((boundElement) => {
            boundElement.setAttribute('data-invalid', 'radio');
        });
    }
};

/** @param {HTMLInputElement} input */
const setValidationState = (input) => {
    /** @type {HTMLLabelElement} */
    const label = input.parentElement;

    if (label.tagName !== 'LABEL') {
        throw Error('Input element should be wrapped by Label element for validation messages');
    }

    if (input.validity.valid) {
        label.removeAttribute('data-invalid');
    } else {
        label.setAttribute('data-invalid', getValidationErrorType(input));

        configureBoundElement(label);
    }
};

/** @param {Event} event */
const validateInput = (event) => {
    event.preventDefault();

    /** @type {HTMLInputElement} */
    const input = event.currentTarget;

    setValidationState(input);

    if (input.type === 'checkbox' || input.type === 'radio') {
        input.addEventListener('change', validateInput);
    } else {
        input.addEventListener('blur', validateInput);
    }
};

const validateForm = () => {
    [...formElement.elements].forEach((input) => {
        input.addEventListener('invalid', validateInput);
    });
};

/**
 * @param {Boolean} isPassed
 */
const showFormState = (isPassed) => {
    if (isPassed && successMessageElement) {
        successMessageElement.parentElement.classList.add(ACTIVE_POST_MESSAGE_CLASS);
    } else if (errorMessageElement) {
        errorMessageElement.parentElement.classList.add(ACTIVE_POST_MESSAGE_CLASS);
    }
};

const hideFormState = () => {
    if (successMessageElement) {
        successMessageElement.parentElement.classList.remove(ACTIVE_POST_MESSAGE_CLASS);
    }

    if (errorMessageElement) {
        errorMessageElement.parentElement.classList.remove(ACTIVE_POST_MESSAGE_CLASS);
    }
};

/** @returns {Promise<Response>} */
const sendForm = async () => {
    const formData = new FormData(formElement);

    return axios.post('/kontakt', formData);
};

/** @returns {Promise<Response>} */
const sendCaptcha = async () => {
    const formData = new FormData();

    formData.append('check', 'true');
    formData.append('recaptcha_response', formElement.elements.recaptcha_response.value);

    return axios.post('/kontakt', formData);
};

/** @param {Event} event */
const processForm = async (event) => {
    event.preventDefault();
    let status = true;

    // disable multiple form submiting
    formElement.removeEventListener('submit', processForm);
    formElement.addEventListener('submit', (innerEvent) => innerEvent.preventDefault());

    hideFormState();

    /** @type {HTMLButtonElement} */
    const formSubmitButton = formElement.querySelector('[data-submit]');

    if (formSubmitButton) {
        formSubmitButton.classList.add('form__btn--loading');
    }

    try {
        const captchaResponse = await sendCaptcha();
        if (captchaResponse.data.err !== 'ok') {
            throw new Error('wrong captcha');
        }

        const formResponse = await sendForm();

        if (formResponse.status !== 200) {
            throw new Error(`wrong request url: ${formResponse.url}`);
        }

        showFormState(true);
        executeCaptcha();
    } catch (error) {
        status = false;
        errorMessageElement.classList.add('message-error--show');
        showFormState(false);
        throw new Error(error.message);
    } finally {
        if (formSubmitButton) {
            formSubmitButton.classList.remove('form__btn--loading');
        }

        // enable submiting form after sending
        formElement.addEventListener('submit', processForm);
    }

    if (status) {
        formElement.remove();
        document.getElementById('contact').scrollIntoView();
        successMessageElement.classList.add('message-error--show');
    }
};

export const form = () => {
    if (!formElement) return;

    validateForm();

    formElement.addEventListener('submit', processForm);
};
