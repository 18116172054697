/*
 * Components
 * */
import './components/menu-control';
import './components/faq-accordion';
import './components/success-return-url';
import { captcha } from './modules/captcha';
import { form } from './modules/form';

captcha();
form();
/**
 * Zpracování asynchronních požadavků + ... pro newsletter
 */
const dynamicNewslettersBar = async () => {
    const newsletterElement = document.querySelector('.newsletters');
    if (newsletterElement) {
        const { newslettersBar } = await import(
            /* webpackChunkName: 'chunk-newsletters-bar' */
            './modules/newsletters-bar'
        );
        newslettersBar();
    }
};

/**
 * stránka smazání účtu - modal, formulář
 */
const dynamicDeleteAccount = async () => {
    const deleteAccountElement = document.querySelector('.delete-account');
    if (deleteAccountElement) {
        const { deleteAccount } = await import(
            /* webpackChunkName: 'chunk-delete-account' */
            './modules/delete-account'
        );
        deleteAccount();
    }
};

/** Spusteni dynamickych importu */
const loadDynamicImports = () => {
    dynamicNewslettersBar();
    dynamicDeleteAccount();
};

loadDynamicImports();
