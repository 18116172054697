const questions = document.querySelectorAll('.premium-contact__faq-block');

for (let i = 0; i < questions.length; i += 1) {
    questions[i].addEventListener('click', function faq(e) {
        if (this.classList.contains('open')) {
            this.classList.remove('open');
        } else {
            /* Commented out to prevent moving the page to user
      const openQuestions = document.querySelectorAll('.open');

      for (let y = 0; y < openQuestions.length; y += 1) {
        openQuestions[y].classList.remove('open');
      }
      */

            this.classList.add('open');
            e.stopPropagation();
        }
    });
}
