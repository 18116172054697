const menuWrap = document.querySelector('.premium-menu__wrap');
const menuHamburger = document.querySelector('.premium-menu__hamburger');
const body = document.querySelector('body');

if (menuHamburger) {
    menuHamburger.addEventListener('click', (e) => {
        menuWrap.classList.toggle('menu-open');
        e.stopPropagation();
    });
}

body.addEventListener('click', () => {
    if (menuWrap) {
        if (menuWrap.classList.contains('menu-open')) {
            menuWrap.classList.remove('menu-open');
        }
    }
});
