const href = new URL(window.location.href);
const successReturnUrl = href.searchParams.get('success_return_url');
const successReturnId = href.searchParams.get('success_return_id');

if (successReturnUrl) {
    try {
        const magazinUrl = new URL(successReturnUrl);
        const magazinProtocol = magazinUrl.protocol;
        const doubleSlash = '//';
        const magazinHostname = magazinUrl.hostname;
        const linkToLogin = '/prihlaseni/login/porta?redirect_uri=';
        const concatUrl = linkToLogin + successReturnUrl.toString();

        localStorage.setItem(
            'successUrl',
            magazinProtocol + doubleSlash + magazinHostname + concatUrl,
        );
        if (successReturnId) {
            localStorage.setItem('successId', successReturnId);
        }
    } catch (_) {
        throw new Error(`success_return_url is not valid url.\n${_}`);
    }
}

const magazineList = [
    {
        domain: 'www.blesk.cz',
        id: 1,
        imageUrl: {
            white: '/images/premium/dist/svg/blesk-logo-white.svg',
            color: '/images/premium/dist/svg/blesk-logo.svg',
        },
    },
    {
        domain: 'www.reflex.cz',
        id: 3,
        imageUrl: {
            white: '/images/premium/dist/svg/reflex-logo-white.svg',
            color: '/images/premium/dist/svg/reflex-logo.svg',
        },
    },
    {
        domain: 'isport.blesk.cz',
        id: 2,
        imageUrl: {
            white: '/images/premium/dist/svg/isport-logo-white.svg',
            color: '/images/premium/dist/svg/isport-logo.svg',
        },
    },
    {
        domain: 'www.e15.cz',
        id: 11,
        imageUrl: {
            white: '/images/premium/dist/svg/e15-logo-white.svg',
            color: '/images/premium/dist/svg/e15-logo.svg',
        },
    },
    {
        domain: 'www.maminka.cz',
        id: 24,
        imageUrl: {
            white: '/images/premium/dist/svg/maminka-logo-white.svg',
            color: '/images/premium/dist/svg/maminka-logo.svg',
        },
    },
    {
        domain: 'www.zive.cz',
        id: 25,
        imageUrl: {
            white: '/images/premium/dist/svg/zive-logo-white.svg',
            color: '/images/premium/dist/svg/zive-logo.svg',
        },
    },
    {
        domain: 'www.abicko.cz',
        id: 5,
        imageUrl: {
            white: '/images/premium/dist/svg/abc-logo-white.svg',
            color: '/images/premium/dist/svg/abc-logo.svg',
        },
    },
    {
        domain: 'www.auto.cz',
        id: 29,
        imageUrl: {
            white: '/images/premium/dist/svg/auto-logo-white.svg',
            color: '/images/premium/dist/svg/auto-logo.svg',
        },
    },
];

function changeArticle(data, magazine) {
    const articleTitle = data.title;
    const articlePerex = data.perex;
    const responseImage = data.promo_img.img;
    const articleImage = `linear-gradient(to bottom, rgba(28,28,28, 0.1), rgba(28,28,28, 0.7) ),
                        url(${responseImage})`;

    if (document.querySelectorAll('.premium-article').length !== 0) {
        const element = document.querySelectorAll('.premium-article')[0].firstElementChild;
        const magazineLogo = element.querySelector('.premium-article__item--logo').children[0];
        const title = element.querySelector('.premium-article__item--bottom').children[1];

        title.firstElementChild.innerText = articleTitle;
        element.style.backgroundImage = articleImage;
        magazineLogo.src = magazine.imageUrl.white;
        magazineLogo.alt = magazine.domain;
    }

    if (document.querySelectorAll('.premium-devices').length !== 0) {
        const wrapper = document.querySelectorAll('.premium-devices__right')[0].firstElementChild;
        const element = wrapper.children[1];
        const magazineLogo = wrapper.children[2].firstElementChild.children[0];

        element.style.backgroundImage = articleImage;
        wrapper.children[3].firstElementChild.children[0].innerText = articleTitle;
        wrapper.children[3].firstElementChild.children[1].innerText = articlePerex.slice(
            3,
            articlePerex.length - 4,
        );
        magazineLogo.src = magazine.imageUrl.white;
        magazineLogo.alt = magazine.domain;
    }
}

function getArticleDetail(magazine, articleId) {
    const magazineId = magazine.id;
    const APIpoint = `https://api.cms.cncenter.cz/api/v1/article-basic/${magazineId}/${articleId}`;
    const xhr = new XMLHttpRequest();
    xhr.open('GET', APIpoint);
    xhr.setRequestHeader('accept', 'application/json');
    xhr.setRequestHeader('X-AUTH-TOKEN', 'ffd4667b0841e2aa76ad83bab9d867e8');

    xhr.onreadystatechange = () => {
        if (xhr.readyState === 4 && xhr.status === 200) {
            const data = JSON.parse(xhr.responseText);
            changeArticle(data.article, magazine);
            localStorage.setItem('articleData', JSON.stringify(data));
            localStorage.setItem('magazine', JSON.stringify(magazine));
        }
    };

    xhr.send();
}
document.addEventListener('DOMContentLoaded', () => {
    if (localStorage.getItem('successUrl') && localStorage.getItem('successId')) {
        const successUrl = localStorage.getItem('successUrl');
        const articleId = localStorage.getItem('successId');
        const magazineUrl = new URL(successUrl);
        const domain = magazineUrl.hostname;
        const magazine = magazineList.find((o) => o.domain === domain);

        if (magazine) {
            getArticleDetail(magazine, articleId);
        }
    } else if (document.referrer.length > 0) {
        const referrerUrl = new URL(document.referrer);
        const referrer = referrerUrl.hostname;

        if (href.hostname !== referrer) {
            localStorage.setItem('referrer', referrer);
        }
    }
});
